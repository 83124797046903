import * as React from "react"

const IykLogo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path d="M16 8a8 8 0 0 1-8 8 8 8 0 0 1-8-8 8 8 0 0 1 16 0z" fill="#222222" />
    <path
      d="m10.3 6.3-2 1.1V5h-.6v2.4l-2-1.1-.3.4 2 1.3-2 1.3.3.5 2-1.2V11h.7V8.6l2 1.1.3-.4L8.6 8l2-1.3-.3-.5Z"
      fill="#ffffff"
    />
  </svg>
)

export default IykLogo
